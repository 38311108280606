import { createStore } from 'vuex'

export default createStore({
  state: {
    players: [],
    game: null,
    configPageSeen: false,
    gameConfig: null,
    gameNames: {
      warmup: 'Aufwärmen',
      schwindelfrei: 'Schwindelfrei',
      'seltsame-würfel': 'seltsame Würfel',
      'under-pressure': 'Under Pressure',
      'wer-wuerfelt': 'Wer würfelt?',
      zahlenchaos: 'Zahlenchaos'
    },
    gameSettings: {
      numStartJoker: 3,
      gamesWithPressure: ['under-pressure', 'wer-wuerfelt', 'zahlenchaos'],
      allGames: ['warmup', 'schwindelfrei', 'seltsame-wuerfel', 'under-pressure', 'wer-wuerfelt', 'zahlenchaos']
    }
  },
  getters: {
  },
  mutations: {
    SET_PLAYERS (state, payload) {
      state.players = payload
    },
    SET_GAME (state, payload) {
      state.game = payload
    },
    SET_GAME_CONFIG (state, payload) {
      state.gameConfig = payload
    },
    SET_CONFIG_PAGE_SEEN (state, payload = true) {
      state.configPageSeen = payload
    }
  },
  actions: {
    setPlayers ({ commit }, players) {
      commit('SET_PLAYERS', players)
      localStorage.setItem('7-11_selectedPlayers', JSON.stringify(players))
    },
    setGame ({ commit }, game) {
      commit('SET_GAME', game)
      localStorage.setItem('7-11_game', game)
    },
    setGameConfig ({ commit }, config) {
      commit('SET_GAME_CONFIG', config)
      localStorage.setItem('7-11_gameConfig', JSON.stringify(config))
    },
    loadEverything ({ commit }) {
      let lsNames = localStorage.getItem('7-11_selectedPlayers')
      if (lsNames) {
        lsNames = JSON.parse(lsNames)
      } else {
        lsNames = ['']
      }
      commit('SET_PLAYERS', lsNames)

      let lsGame = localStorage.getItem('7-11_game')
      if (!lsGame) {
        lsGame = null
      }

      commit('SET_GAME', lsGame)

      let lsGameConfig = localStorage.getItem('7-11_gameConfig')
      if (lsGameConfig) {
        lsGameConfig = JSON.parse(lsGameConfig)
      } else {
        lsGameConfig = null
      }

      commit('SET_GAME_CONFIG', lsGameConfig)
    },
    setConfigPageSeen ({ commit }) {
      commit('SET_CONFIG_PAGE_SEEN')
    }
  },
  modules: {
  }
})

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import '@mdi/font/css/materialdesignicons.min.css'

import './main.scss'

createApp(App).use(store).use(router).component('v-select', vSelect).mount('#app')

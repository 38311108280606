<template>
<div id="main" class="bg-dark text-white text-center">
  <router-view class="router-view" />
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'App',
  methods: {
    ...mapActions(['loadEverything'])
  },
  computed: {
    ...mapState(['players', 'game'])
  },
  mounted () {
  },
  created () {
    this.$router.beforeEach((to, from) => {
      if (to.meta.needPlayers) {
        if (this.players.length < 2) {
          return '/'
        } else return true
      }
      if (to.meta.needGame) {
        if (this.game === null) {
          return '/game-selection'
        } else return true
      }
    })

    this.loadEverything()
  },
  watch: {
  }
}
</script>

<style>
html, body, #app, #main {
  height: 100%;
  width: 100%;
}

.router-view {
  padding: 16px;
}

.gradient-pts-lr {
  background-image: linear-gradient(to right, var(--bs-primary), #2E8FA8, var(--bs-secondary));
}

.btn-back {
  position: fixed;
  left: 16px;
  top: 35px;
}

.btn-back > * {
  font-size: 30px;
}
</style>

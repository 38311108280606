import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/player-selection',
    name: 'PlayerSelection',
    component: () => import('../views/PlayerSelection.vue')
  },
  {
    path: '/game-selection',
    name: 'GameSelection',
    meta: {
      needPlayers: true
    },
    component: () => import('../views/GameSelection.vue')
  },
  {
    path: '/game-configuration',
    name: 'GameConfiguration',
    meta: {
      needPlayers: true,
      needGame: true
    },
    component: () => import('../views/GameConfiguration.vue')
  },
  {
    path: '/game-manual',
    name: 'GameManual',
    meta: {
      needPlayers: true,
      needGame: true
    },
    component: () => import('../views/GameManual.vue')
  },
  {
    path: '/game',
    name: 'Game',
    meta: {
      needPlayers: true,
      isGame: true
    },
    component: () => import('../views/Game.vue')
  },

  // catch all other requests
  {
    path: '/:catchAll(.*)',
    redirect: '/home'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
